import React from 'react';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state/add-embroidery-state';
import { extractState } from 'ui/component/embroidery-configurator/pages/util';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Section } from './section';
import { SectionError } from './section-error';
import { TextContainer } from './text-container';
import { LogoContainer } from './logo-container';
import { FlagContainer } from './flag-container';
import { SpecialInstructionsContainer } from './special-instruction-container';
import { AlterationPipingContainer } from './alteration-piping-container';
import { PrintScreenContainer } from './print-screen-container';
import ExpandableList from './expandable-list';
import styles from './style.css';

interface ISectionsProps {
    embroiderer: EmbroidererProps;
    embroideryPage: any;
}

const staticOptionLabel: {[key: string]: string} = {
    text: 'Text',
    logos: 'Logos',
    flags: 'Flags',
    'special-instructions': '',
    'alteration-piping': 'Alteration',
    'print-screen': 'Print Screen',
};

export const Sections = ({ embroiderer, embroideryPage }: ISectionsProps) => {
    const { isSaveFailed } = embroiderer;

    const t = usePhraseTranslater();
    const alterationData: React.JSX.Element[] = [];
    const printScreenData: React.JSX.Element[] = [];
    let specialInstructionSectionData: React.JSX.Element | null = null;

    return (
        <div className={styles.sections}>
            {embroiderer.options.map((option) => {
                const isSectionInvalid = SectionError({
                    isSaveFailed,
                    option,
                    embroiderer,
                });

                if (option.type === 'text') {
                    const currentPageData = extractState(embroiderer, 'text');
                    if (!currentPageData.embTextLines.length) {
                        return null;
                    }
                }

                if (option.type === 'alteration-piping') {
                    alterationData.push(
                        <>
                            <div className={styles.containerWrapper}>
                                <AlterationPipingContainer embroiderer={embroiderer} option={option} />
                            </div>
                        </>,
                    );
                    return null;
                }

                if (option.type === 'print-screen') {
                    printScreenData.push(
                        <>
                            <div className={styles.containerWrapper}>
                                <PrintScreenContainer embroiderer={embroiderer} option={option} />
                            </div>
                        </>,
                    );
                    return null;
                }

                if (option.type === 'special-instructions') {
                    specialInstructionSectionData = (
                        <Section
                            key={option.label}
                            className={option.type}
                            handleEdit={embroideryPage.openOption(option)}
                            handleToggle={embroideryPage.toggleOption(option)}
                            optionType={option.type}
                            isLocked={option.isLocked}
                            label={staticOptionLabel[option.type]}
                            isSectionInvalid={isSectionInvalid}
                        >
                            {option.type === 'special-instructions' && (
                                <div className={styles.containerWrapper}>
                                    <SpecialInstructionsContainer embroiderer={embroiderer} />
                                </div>
                            )}
                        </Section>
                    );

                    return null;
                }

                return (
                    <Section
                        key={option.label}
                        className={option.type}
                        handleEdit={embroideryPage.openOption(option)}
                        handleToggle={embroideryPage.toggleOption(option)}
                        optionType={option.type}
                        isLocked={option.isLocked}
                        label={staticOptionLabel[option.type]}
                        isSectionInvalid={isSectionInvalid}
                    >
                        {option.type === 'text' && (
                            <div className={styles.containerWrapper}>
                                <TextContainer
                                    embroiderer={embroiderer}
                                    parentEmbroideryPage={embroideryPage}
                                    option={option}
                                    isSectionInvalid={isSectionInvalid}
                                    isSaveFailed={isSaveFailed}
                                />
                            </div>
                        )}
                        {option.type === 'logos' && (
                            <div className={styles.containerWrapper}>
                                <LogoContainer
                                    embroiderer={embroiderer}
                                    parentEmbroideryPage={embroideryPage}
                                    option={option}
                                />
                            </div>
                        )}
                        {option.type === 'flags' && (
                            <div className={styles.containerWrapper}>
                                <FlagContainer
                                    embroiderer={embroiderer}
                                    parentEmbroideryPage={embroideryPage}
                                    option={option}
                                />
                            </div>
                        )}
                        {option.type === 'special-instructions' && (
                            <div className={styles.containerWrapper}>
                                <SpecialInstructionsContainer embroiderer={embroiderer} />
                            </div>
                        )}
                        {option.type === 'alteration-piping' && (
                            <div className={styles.containerWrapper}>
                                <AlterationPipingContainer embroiderer={embroiderer} option={option} />
                            </div>
                        )}
                        {option.type === 'print-screen' && (
                            <div className={styles.containerWrapper}>
                                <PrintScreenContainer embroiderer={embroiderer} option={option} />
                            </div>
                        )}
                    </Section>
                );
            })}
            {
                (
                    alterationData.length > 0 || printScreenData.length > 0
                ) && (
                    <Section
                        key="alteration-piping"
                        className="alteration-piping"
                        label="Customization"
                        optionType="alteration-piping"
                    >
                        <div className={styles.customizationPreHeading}>{t('pre-selected')}</div>

                        <ExpandableList
                            title={t('Alteration')}
                            data={alterationData}
                            styles={styles}
                        />
                        <ExpandableList
                            title={t('Print Screen')}
                            data={printScreenData}
                            styles={styles}
                        />
                    </Section>
                )
            }
            {specialInstructionSectionData && specialInstructionSectionData}
        </div>
    );
};
